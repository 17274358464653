<template>
  <div class="app-container">
    <el-card>
      <div class="info">
        <span>分值：</span>
        <div class="block">
          <el-input-number v-model="minScore"></el-input-number>
        </div>
        <div class="block">
          <el-input-number v-model="maxScore"></el-input-number>
        </div>

        <span>院系：</span>
        <el-select v-model="depId" placeholder="请选择" clearable @change="changeSystem">
          <el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
        <span>班级：</span>
        <el-select v-model="classId" placeholder="请选择" clearable>
          <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </div>

      <div class="info">
        <span>时间：</span>
        <div class="block">
          <el-date-picker v-model="searchForm.value"
            type="daterange"
            start-placeholder="开始日期" end-placeholder="结束日期"
            @change="pickchange"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </div>
        
        <div>
          <el-button type="primary" @click="goSearch(searchForm)">查询</el-button>
        <el-button type="primary" @click="addInfo" plain style="display:none;">新增</el-button>
        </div>
      </div>
      <!-- 列表区域 -->
      <div class="table-content">
        <el-table
          class="mgt16"
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          :data="tableData"
        >
          <!-- stripe: 斑马条纹
        border：边框-->
          <el-table-column label="序号" align="center" type="index" width="60px"></el-table-column>
          <el-table-column prop="title" label="标题" align="left"></el-table-column>
          <el-table-column prop="content" label="内容" align="left"></el-table-column>
          <el-table-column prop="score" label="当前得分" align="left"></el-table-column>
          <el-table-column prop="classname" label="班级" align="left"></el-table-column>
          <el-table-column prop="classnum" label="班号" align="left"></el-table-column>
          <el-table-column prop="depname" label="院系" align="left"></el-table-column>
          <el-table-column prop="createtime" label="发送时间" align="left" :formatter="formatDate"></el-table-column>
          <el-table-column property="address" label="操作" align="center" width="150px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleView(scope.row.id)"
                >详情</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog
        v-if="dialogFormVisible"
        :title="textMap[dialogStatus]"
        :visible.sync="dialogFormVisible"
        :before-close="handleClose"
        width="30%"
        minWidth="600px"
      >
        <addInformation
          @changeDialogFormVisible="changeDialogFormVisible($event)"
          @goGetList="goSearch"
          :dialog-status="dialogStatus"
          :rowData="form"
          :tableData="tableData"
        />
      </el-dialog>

      <!-- 分页区域 -->
      <el-pagination
        class="mgt16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import * as api from "@/api/users";
import addInformation from "../add/addInformation.vue";
export default {
  components: {
    addInformation,
  },
  data() {
    return {
      systemList: [], // 院系列表
      classList: [], // 班级列表

      depId: null,  // 院系ID
      classId: null, // 班级ID
      studentId: null, // 学员ID
      studentName: "",
      studentNum: "",
      minScore: 0,
      maxScore: 0,
      beginTime: null, // this.$moment().startOf("month").format("YYYY-MM-DD"),
      endTime: null, // this.$moment().endOf("month").format("YYYY-MM-DD"),

      value: "",
      total: 0,
      roles: [],
      dialogFormVisible: false,
      dialogStatus: "",
      tableData: [],
      searchForm: {},
      form: {},
      textMap: {
        update: "编辑",
        create: "新增",
        view: "详情",
      },
      page: 1,
      pageSize: 10,
      listQuest: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    // 先获取当前用户
    api.getCurrUserItem().then((res) => {
      let depId = null; // 院系id
      let classId = null; // 班级id
      let studentId = null; // 学生id
      let studentNum = ""; // 学生id
      if (res.data.roleid == 3) {
        depId = res.data.depid;
      } else if (res.data.roleid == 2 || res.data.roleid == 6) {
        depId = res.data.depid;
        classId = res.data.classid;
      } else if (res.data.roleid == 5 || res.data.roleid == 7) {
        depId = res.data.depid;
        classId = res.data.classid;
        studentId = res.data.id;
        studentNum = res.data.studentNum;
      }
      this.depId = depId;
      this.classId = classId;
      this.studentId = studentId;
      this.studentNum = studentNum;
    });
    this.getSystemList();
    this.goSearch();
  },
  methods: {
    // 获取院系列表
    getSystemList() {
      let { page, pageSize } = this;
      let params = {
        page,
        pageSize,
      };
      // 院系接口
      api.getSystemList(params).then((res) => (this.systemList = res.data.records));
    },
    // 切换院系
    changeSystem(id) {
      api.getCardArgumentDetail({ depid: id }).then((res) => {
        let classList = res.data.records.filter((item) => item.depid === id);
        this.classList = classList;
        this.classId = null;
        if (classList != null && classList.length > 0) {
          this.classId = classList[0].id;
        }
      });
    },

    formatDate(row) {
      return (
        row.createtime.substring(0, 10) + " " + row.createtime.substring(11, 19)
      );
    },
    goSearch() {
      this.getType();
      // 先获取当前用户
      api.getCurrUserItem().then((res) => {
        let depId = this.depId; // 院系id
        let classId = this.classId; // 班级id
        let studentId = this.studentId; // 学生id
        if (res.data.roleid == 3) {
          depId = res.data.depid;
        } else if (res.data.roleid == 2 || res.data.roleid == 6) {
          depId = res.data.depid;
          classId = res.data.classid;
        } else if (res.data.roleid == 5 || res.data.roleid == 7) {
          depId = res.data.depid;
          classId = res.data.classid;
          studentId = res.data.id;
        }

        api.searchNews({
            minScore: this.minScore,
            maxScore: this.maxScore,
            beginTime: this.beginTime,
            endTime: this.endTime,
            studentName: this.studentName,
            studentNum: this.studentNum,
            
            depId: depId,
            classId: classId,
            studentId: studentId,

            page: this.page,
            pageSize: this.pageSize,
            heightFlag: 1, // 1 高分 0 低分
            classFlag: 1, // 1 班级 0 学生
          })
          .then((res) => {
            if (res.status == 1) {
              this.tableData = res.data.rows;
              this.total = res.data.total;
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    // 获取选择时间范围
    pickchange(e) {
      this.beginTime = this.$moment(e[0]).format("YYYY-MM-DD");
      this.endTime = this.$moment(e[1]).format("YYYY-MM-DD");
      console.log(this.beginTime, this.endTime);
    },
    addInfo() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    handleDelete(row) {
      console.log(row.id);
      api.removeNews(row.id).then((res) => {
        console.log(res);
        if (res.status == 1) {
          this.$confirm("确认关闭？").then(() => {
            this.goSearch();
            this.$message.success(res.msg);
          });
        } else {
          this.$message.success(res.msg);
        }
      });
    },
    // 详情
    handleView(id) {
      this.dialogStatus = "view";
      api.findById(id).then((res) => {
        Object.assign(this.form, res.data);
        this.dialogFormVisible = true;
      });
    },
    indexMethod(index) {
      return index * 1;
    },
    changeDialogFormVisible(data) {
      this.dialogFormVisible = data;
      api.searchNews(this.listQuest).then((res) => {
        if (res.status == 1) {
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    getType() {
      api.getCurrUserItem().then((res) => {
        this.idis = res.data.rname;
      });
    },
    handleClose(done) {
      done();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.goSearch();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.goSearch();
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  width: 200px;
  // background: red;
  margin: 20px auto 0;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.tabs div {
  width: 40px;
  height: 40px;
}
.active {
  background: #409eff;
  color: #fff;
}
/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}
.info {
  display: flex;
  margin-bottom:10px;
  span {
    line-height: 41px;
  }
}
.classbox {
  width: 100%;
  display: flex;
}
.test2 {
  width: 100%;
  height: 250px;
}
.test2 div {
  width: 100%;
  height: 100%;
}
/deep/.el-dialog__body {
  padding: 0 !important;
}
</style>
